import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Paper, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es-mx';
import * as React from 'react';
import { Calendar, Views, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as Redux from 'react-redux';
import { dateformat } from '../../../../helpers';
import { PARAMS_EVENTOS, SLOTINFO_EVENTOS } from '../../../../store/Types';
import { getAllEventosAction, setActivoEventosAction } from '../EventosAction';

dayjs.locale(es);
var _timezone = require('dayjs/plugin/timezone');
dayjs.extend(_timezone);
dayjs.tz.setDefault('America/Caracas');
const localizer = dayjsLocalizer(dayjs);

export const messagesCalendar = {
  day: 'Día',
  week: 'Semana',
  agenda: 'Diario',
  month: 'Mes',
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
};

const CalendarioEventosComponent = ({
  viewCalendar = Views.MONTH,
  isDetalles = false,
  setOpenModalForm = () => {},
}) => {
  const dispatch = Redux.useDispatch();

  const eventosStore = Redux.useSelector((state) => state.eventos);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const layoutStore = Redux.useSelector((state) => state.layout);

  const [openModalDetalles, setOpenModalDetalles] = React.useState(false);

  const [eventos, setEventos] = React.useState([]);
  const [view, setView] = React.useState(viewCalendar);

  React.useEffect(() => {
    const fetchData = async () => {
      const payload = {
        entityId: entidadesStore.activo.id,
        ...eventosStore.params,
      };
      dispatch({
        type: PARAMS_EVENTOS,
        payload,
      });
      if (eventosStore.all && eventosStore.all.length === 0) {
        await getAllEventosAction(dispatch, payload);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createEvent(el, date) {
    return {
      id: el.id,
      evento: el,
      color: el.color,
      title: el.name,
      start: dayjs(`${date} ${el.startTime ?? '00:00:00'}`).toDate(),
      end: dayjs(`${date} ${el.endTime ?? '23:59:00'}`).toDate(),
    };
  }

  function addEvents(el, startDate, endDate) {
    const events = [];
    while (startDate.isSameOrBefore(endDate)) {
      events.push(createEvent(el, startDate.format('YYYY-MM-DD')));
      startDate = startDate.add(1, 'day');
    }
    return events;
  }

  React.useEffect(() => {
    const events = eventosStore.all.flatMap((el) => {
      const startDate = dayjs(el.date);
      const endDate = dayjs(el.endDate);
      return addEvents(el, startDate, endDate);
    });

    setEventos(events);
  }, [eventosStore.all]);

  const event = (eventProps) => {
    const { event } = eventProps;
    const isMonth = view === Views.MONTH;
    // Personaliza el estilo de cada evento
    const eventStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '10vw',
    };

    const styleTextCalendar = {
      fontSize: isMonth ? '10pt' : '12pt',
      fontWeight: 'bold',
    };

    return (
      <Box sx={eventStyle}>
        {event.evento ? (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              {isMonth &&
                (event.evento.public ? (
                  <PeopleIcon fontSize="inherit" sx={{ color: 'white' }} />
                ) : (
                  <PersonIcon fontSize="inherit" sx={{ color: 'white' }} />
                ))}
              <Typography textAlign="center" sx={styleTextCalendar}>
                {event.title}
              </Typography>
            </Stack>
            {!isMonth && (
              <Typography
                sx={{
                  ...styleTextCalendar,
                  fontSize: '7pt',
                }}
              >
                {event.evento.desc}
              </Typography>
            )}
            {/* <Typography sx={{ fontSize: '9pt' }}>
              {event.evento.seccion.nivelAcademico.nivelAcademico.abrev}
              {' - '}
              {event.evento.seccion.nombre}
            </Typography>
            <Typography sx={{ ...styleTextCalendar, fontSize: '8pt' }}>
              {event.evento.docente.usuario.perfil.pnombre}{' '}
              {event.evento.docente.usuario.perfil.papellido}
            </Typography>

            {!eventosStore.settings.isEstatus && event.evento.aula ? (
              <Typography
                sx={{
                  ...styleTextCalendar,
                  fontSize: '8pt',
                }}
              >
                Aula: {event.evento.aula?.nombre}
              </Typography>
            ) : null}
            {!eventosStore.settings.isEstatus ? null : (
              <Chip
                label={event.evento.estatus.nombre}
                size="small"
                sx={{
                  fontSize: '7pt',
                }}
                color={event.evento.estatus.id === 1 ? 'success' : 'error'}
              />
            )} */}

            {/* {!isMonth && (
              <Stack direction="row" spacing={1} alignItems="center">
                {event.evento.public ? (
                  <PeopleIcon fontSize="inherit" sx={{ color: 'white' }} />
                ) : (
                  <PersonIcon fontSize="inherit" sx={{ color: 'white' }} />
                )}
                <Typography
                  variant="caption"
                  color="white"
                  sx={{ fontWeight: 'bold' }}
                >
                  {event.evento.public ? 'Público' : 'Personal'}
                </Typography>
              </Stack>
            )} */}
          </>
        ) : (
          <>
            <Typography
              sx={{ textAlign: 'start', fontSize: '9pt', width: '100%' }}
            >
              {event.title}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  async function handleMonthChange(date) {
    console.log('El date: ', date);

    if (!date.start) {
      const newDate = dateformat(date[0], { full: true });
      const newEndDate = dateformat(date[6], { full: true });
      await getAllEventosAction(dispatch, {
        ...eventosStore.params,
        date: newDate,
        endDate: newEndDate,
        perMonth: null,
      });
      return;
    }

    const newDate = dateformat(date.start, { full: true });
    await getAllEventosAction(dispatch, {
      ...eventosStore.params,
      date: dateformat(dayjs(newDate).add(1, 'month'), { full: true }),
      perMonth: true,
    });
  }

  return (
    <Paper elevation={4} sx={{ p: 2 }}>
      <Calendar
        messages={messagesCalendar}
        localizer={localizer}
        defaultDate={dayjs()}
        style={{ height: '100vh' }}
        events={eventos}
        views={['month', 'week']}
        view={view}
        onView={(newView) => setView(newView)}
        selectable
        popup
        onSelectSlot={(slotInfo) => {
          setActivoEventosAction(dispatch, null);

          // Crear una nueva fecha para end sin mutar la original
          const adjustedEndDate = new Date(slotInfo.end);
          adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

          dispatch({
            type: SLOTINFO_EVENTOS,
            payload: {
              ...slotInfo,
              end: adjustedEndDate,
            },
          });
          setOpenModalForm(true);
        }}
        components={{ event }}
        onRangeChange={handleMonthChange}
        onNavigate={(x, y, z) => {}}
        eventPropGetter={(event) => {
          let style = {
            backgroundColor: event.color ?? '#2b2b2b',
            color: 'white',
            borderRadius: '7px',
            border:
              event.id === eventosStore.activo?.id
                ? 'solid 3px blue'
                : event.horario?.estatus.id === 2
                ? 'solid 1px red'
                : 'solid 1px white',
            fontSize: '10pt',
          };

          if (event.isMine) {
            style.backgroundColor = 'lightgreen';
          }

          return {
            className: '',
            style,
          };
        }}
        onSelectEvent={(event, e) => {
          setActivoEventosAction(dispatch, event.evento);
          if (isDetalles) {
            setOpenModalDetalles(true);
            return;
          }
          setOpenModalForm(true);
        }}
      />
    </Paper>
  );
};

export default CalendarioEventosComponent;
